import React, { useCallback, useEffect, useState } from "react";
import { IMainPage } from "@villa/types/mainPage";
import { OfferCard } from "@/lib/ui/components/Cards";
import cl from "classnames";
import styles from "./OffersSlider.module.scss";
import useEmblaCarousel from "embla-carousel-react";
import { CircleButton } from "@/lib/ui/components/Buttons";
import { ArrowIcon } from "@/lib/ui/icons";

interface IProps {
  data: IMainPage | undefined;
  theme?: "light" | "dark";
}

const OffersSlider = ({ data, theme = "light" }: IProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "start",
    breakpoints: {
      "(max-width: 996px)": { loop: true },
    },
  });
  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = useCallback(() => {
    if (!emblaApi) return;
    const slideNum = emblaApi.selectedScrollSnap();

    setCurrentSlide(() => slideNum);
  }, [emblaApi]);

  useEffect(() => {
    changeSlide();
    if (emblaApi) {
      emblaApi?.on("select", changeSlide);
    }
    () => emblaApi?.off("select", changeSlide);
  }, [changeSlide, emblaApi]);

  return (
    <div
      className={cl(
        styles.offersSlider__wrapper,
        styles[`offersSlider__wrapper--${theme}`],
      )}
      ref={emblaRef}
    >
      <div className={styles.offersSlider}>
        {data?.ownProjects.map((ownProject, index) => {
          const onClick = () => {
            emblaApi && emblaApi.scrollTo(index);
          };
          return (
            <OfferCard
              key={index}
              ownProject={ownProject}
              index={index}
              theme={theme}
              length={data?.ownProjects.length}
              onClick={onClick}
            />
          );
        })}
      </div>
      <div
        className={cl(
          styles.offersSlider__buttons,
          styles["offersSlider__buttons--mobile"],
        )}
      >
        <CircleButton
          name="scroll to previous"
          as={"button"}
          content={<ArrowIcon />}
          theme={theme === "light" ? "light" : "mixed"}
          onClick={scrollPrev}
          styleType="secondary"
          className={cl(
            styles.offersSlider_reviewPrev,
            currentSlide !== 0 && styles["offersSlider_reviewPrev--visible"],
          )}
        />
        <CircleButton
          name="scroll to next"
          as={"button"}
          content={<ArrowIcon isRight />}
          theme={theme === "light" ? "light" : "mixed"}
          onClick={scrollNext}
          styleType="secondary"
          className={cl(
            styles.offersSlider_reviewNext,
            currentSlide !== (data?.ownProjects?.length || 3) - 1 &&
              styles["offersSlider_reviewNext--visible"],
          )}
        />
      </div>
      <div className={styles.offersSlider__buttons}>
        <CircleButton
          name="scroll to previous"
          as={"button"}
          content={<ArrowIcon />}
          theme={theme === "light" ? "light" : "mixed"}
          onClick={scrollPrev}
          className={cl(
            styles.offersSlider_reviewPrev,
            currentSlide !== 0 && styles["offersSlider_reviewPrev--visible"],
          )}
        />
        <CircleButton
          name="scroll to next"
          as={"button"}
          content={<ArrowIcon isRight />}
          theme={theme === "light" ? "light" : "mixed"}
          onClick={scrollNext}
          className={cl(
            styles.offersSlider_reviewNext,
            currentSlide !== (data?.ownProjects?.length || 3) - 1 &&
              styles["offersSlider_reviewNext--visible"],
          )}
        />
      </div>
    </div>
  );
};

export default OffersSlider;
