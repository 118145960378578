import React from "react";
import { useTranslation } from "next-i18next";
import { IMainPage } from "@villa/types/mainPage";
import { useWindowSize } from "@villa/hooks/useWindowSize";
import { YouTubeCard, DefaultMediaCard, InstagramCard } from "@/lib/ui/components/Cards";
import styles from "./ShareContent.module.scss";
import { H1 } from "@/lib/ui/components/Typography";
import cn from "classnames";

interface IProps {
  data: Pick<IMainPage, 'instagramPosts' | 'youTubeVideos'>;
  className?: string;
}
export const ShareContent: React.FC<IProps> = ({ data, className }) => {
  const { t } = useTranslation("index");

  const { isMobile } = useWindowSize(996);

  return (
    <div className={cn(styles.share, className)}>
      <div className={styles.container}>
        <div>
          <H1 as={'h2'} className={styles.share_title}>
            {t("sharingExclusiveContentFromTheIsland")}
          </H1>

          <div className={styles.share__mediaCards}>
            <div className={styles.share__mediaVideo}>
              {data.youTubeVideos.slice(0, 3).map((item, index) => (
                <YouTubeCard key={index} image={item.image} path={item.publicationId} />
              ))}
              <DefaultMediaCard
                variant={"youTube"}
                text={t("watchExpertReviews")}
                link={"https://www.youtube.com/@VillaCartePhuket/videos"}
              />
            </div>

            <div className={styles.share__mediaPhoto + " photoCards"}>
              {isMobile
                ? data.instagramPosts
                    .slice(0, 3)
                    .map((item, index) => (
                      <InstagramCard
                        key={index}
                        image={item.image}
                        path={item.publicationId}
                      />
                    ))
                : data.instagramPosts
                    .slice(0, 5)
                    .map((item, index) => (
                      <InstagramCard
                        key={index}
                        image={item.image}
                        path={item.publicationId}
                      />
                    ))}
              <DefaultMediaCard
                variant={"insta"}
                text={t("learnAllAboutRealEstateInThailand")}
                link={
                  "https://www.instagram.com/villacarte.vip/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
