import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Button from "@/lib/ui/components/Buttons/Button/Button";
import styles from "./JoinTheClub.module.scss";
import cn from "classnames";
import { H2, P3 } from "@/lib/ui/components/Typography";
import { ArrowIcon } from "@/lib/ui/icons";
import Link from "next/link";

type JoinTheClubProps = {
  title?: string;
  description?: string;
  withoutButton?: boolean;
};

export const JoinTheClub: FC<JoinTheClubProps> = ({
  title,
  description,
  withoutButton,
}) => {
  const { t, i18n: {language} } = useTranslation("index");
  return (
    <div
      className={cn(
        styles.club,
        styles.container,
        withoutButton && styles.club__withoutButton,
      )}
    >
      <div
        className={cn(
          styles.club__content,
          withoutButton && styles.club__content_withoutButton,
        )}
      >
        {withoutButton && <div className={styles.club__withoutButton_ellipse} />}
        <H2>{title || t("joinThePrivateVillaCarteClub")}</H2>

        <P3>{description || t("youBecomeAClubMember")}</P3>

        {!withoutButton && (
          <Button
            className={styles.club__button}
            label={t("learnMoreSmall")}
            theme={"dark-invert"}
            size={"lg"}
            as={Link}
            target="_blank"
            href={`https://villacarte.com/${language}/company/loyalty-program`}
            icon={<ArrowIcon isRight />}
          />
        )}
      </div>
      <Image
        className={styles.club__leavesMobile}
        fill
        src={"/images/club/leaves-mobile.webp"}
        // sizes="(max-width: 1624px): 1624px, (max-width: 1344px) 1345px, 50vw"
        sizes="100vw"
        alt=""
      />
      <Image
        className={styles.club__leaves}
        fill
        src={"/images/club/leaves.webp"}
        alt=""
        // sizes="(max-width: 1624px): 1624px, (max-width: 1344px) 1345px, 50vw"
        sizes="(min-width: 1640px) 1624px, (min-width: 1420px) 1352px, calc(93.29vw + 39px)"
      />
      <Image
        className={styles.club__bg}
        fill
        src={"/images/club/bg.webp"}
        // sizes="(max-width: 1624px): 1624px, (max-width: 1344px) 1345px, 50vw"
        sizes="(min-width: 1640px) 1624px, (min-width: 1420px) 1352px, calc(95vw + 15px)"
        alt={""}
      />
    </div>
  );
};
