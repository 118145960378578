import React from 'react';
import {useTranslation} from "next-i18next";
import { TitleTextArrowCard } from '@/lib/ui/components/Cards/TitleTextArrowCard';

type AllServicesCardsProps = {
    className: string;
}

const AllServicesCards = (props : AllServicesCardsProps) => {
    const { t, i18n: {language} } = useTranslation("index");

	const propertySale = t("propertySaleCard");
	const villasAndApartmentsForLife= t("villasAndApartmentsForLife");
	const propertyForRent= t("propertyForRent");
	const premiumAndLuxuryVillas= t("premiumAndLuxuryVillas");
	const concierge= t("conciergeCard");
	const anyIssuesAreSolvedForYou= t("anyIssuesAreSolvedForYou");
	const propertyManagement= t("propertyManagement");
	const passiveIncomeWithoutWorries= t("passiveIncomeWithoutWorries");

	const allServices = [
		{ title: propertySale || '', text: villasAndApartmentsForLife || '', href: '/sale/catalog' },
		{ title: propertyForRent || '', text: premiumAndLuxuryVillas || '', href: `https://villacarte.com/${language}/rent` },
		{ title: concierge || '', text: anyIssuesAreSolvedForYou || '', href: `https://villacarte.com/${language}/concierge` },
		{ title: propertyManagement || '', text: passiveIncomeWithoutWorries || '', href: '/propertymanagement'},
	];

    return (
        <div className={props.className}>
            {allServices.map((item, index) =>
                <TitleTextArrowCard
                    key={index}
                    title={item.title}
                    name={item.title}
                    text={item.text}
                    href={item.href}
                />
            )}
        </div>
    )
}

export default AllServicesCards;